import React, { useState } from "react"

const JobPosting = ({ heading, children}) => {

    const [accordionOpen, setAccordionOpen] = useState(false);

    return (
        <li className="py-4 mx-auto text-gray-700 md:py-6">
            <button aria-label={heading} className="flex flex-row items-center justify-between w-full" onClick={() => setAccordionOpen((o) => !o)}>
                <h2 className="text-xl font-bold md:text-4xl">{heading}</h2>
                <div> 
                    <svg className={`${!accordionOpen ? 'block' : 'hidden'} h-6 md:h-8`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                    <svg className={`${accordionOpen ? 'block' : 'hidden'} h-6 md:h-8`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </button>
            <div className={`${accordionOpen ? 'block' : 'hidden'} mt-4 md:mt-8`}>
                {children}
            </div>
        </li>
    );
};

export default JobPosting;
