import * as React from "react"
import JobPosting from "../../../components/JobPosting";
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"

const CareersPage = () => {

  const EmploymentFacts = () => {
    return(
      <section className="grid max-w-screen-xl grid-cols-1 gap-4 mx-4 my-4 md:my-10 lg:grid-cols-3 y-6 lg:mx-auto">
        <article className="p-6 bg-white border-2 border-gray-100 rounded-lg">
          <h3 className="mb-3 text-xl font-semibold">Annual leave</h3>
          <p className="text-gray-700">
            We provide four weeks annual leave to all employees accrued over a year of employment.
          </p>
        </article>
        <article className="p-6 bg-white border-2 border-gray-100 rounded-lg">
          <h3 className="mb-3 text-xl font-semibold">Flexible schedule</h3>
          <p className="text-gray-700">
            We trust you to organise your day appropriate to your life. Your
            work judged by out put not hours spent at the desk.
          </p>
        </article>
        <article className="p-6 bg-white border-2 border-gray-100 rounded-lg">
          <h3 className="mb-3 text-xl font-semibold">Parental leave</h3>
          <p className="text-gray-700">
            Enjoy 12 weeks of paid paternity leave, spend time with your family and
            welcome the newest member.
          </p>
        </article>
        <article className="p-6 bg-white border-2 border-gray-100 rounded-lg">
          <h3 className="mb-3 text-xl font-semibold">Professional development</h3>
          <p className="text-gray-700">
            We contribute AUD $1000 per year towards courses, conferences or other
            professional development activities to support your position. <span className="text-gray-500">Available after a year of employment.</span>
          </p>
        </article>
        <article className="p-6 bg-white border-2 border-gray-100 rounded-lg">
          <h3 className="mb-3 text-xl font-semibold">Live where you want</h3>
          <p className="text-gray-700">
            Anomaly has offices in Canberra, Adelaide and Wagga Wagga. Work from
            one of these locations or where you live with an overlap with the
            Australian EST work hours.
          </p>
        </article>
        <article className="p-6 bg-white border-2 border-gray-100 rounded-lg">
          <h3 className="mb-3 text-xl font-semibold">Don't work alone</h3>
          <p className="text-gray-700">
            There are several benefits from working around other people. If you 
            aren't located where we have offices, we will contributed towards
            a co-working space for you to work out of.
          </p>
        </article>
        <article className="p-6 bg-white border-2 border-gray-100 rounded-lg">
          <h3 className="mb-3 text-xl font-semibold">Get peer feedback</h3>
          <p className="text-gray-700">
            We focus heavily on documentation, code reviews and providing feedback
            to each other. We make technical decisions as a team and ensure that
            we contribute back to each other's knowledge.
          </p>
        </article>
        <article className="p-6 bg-white border-2 border-gray-100 rounded-lg">
          <h3 className="mb-3 text-xl font-semibold">Fair pay &amp; rises</h3>
          <p className="text-gray-700">
            Our salaries are matched to industry standards and a fair compensation
            for your skills and time. Salaries are reviewed annually and are adjusted
            based on performance and market conditions.
          </p>
        </article>
        <article className="p-6 bg-white border-2 border-gray-100 rounded-lg">
          <h3 className="mb-3 text-xl font-semibold">Tools of the trade</h3>
          <p className="text-gray-700">
            All equipment required to fulfil your duties are provided by Anomaly.
            We prefer to use Apple products and preferably deploy our workloads on
            Linode's infrastructure.
          </p>
        </article>
      </section>
    );
  }

  return (
    <Layout>
      <Seo 
        title="Careers"
        description="Come work with us"
      />
      <div className="mx-4 lg:max-w-screen-lg lg:mx-auto"> 
        <h1 className="mb-4 text-5xl font-bold text-anomaly-red">Careers</h1>
        <p className="mb-10 text-2xl font-medium text-gray-500 lg:max-w-2xl">
          Work with engineers and designers that care.
        </p>
      </div>
      <h2 className="max-w-screen-lg mx-4 text-4xl font-bold lg:mx-auto text-anomaly-red">
        Open Positions
      </h2>
      <ul className="mx-2 my-10 border-red-100 divide-y-2 divide-red-100 md:max-w-screen-md border-y-2 md:mx-auto">
        <JobPosting heading="Lead Artist">
          <p className="mb-4">
          Anomaly is seeking a lead artist to brand and relish products across their client 
          portfolio and products that the business is pursuing.
          </p>
          <p className="mb-4">
          We are looking for a particular style and will require the artist to put forward a 
          vision for the company’s products and brands alike.
          </p>
          <p className="mb-4">
          As we approach a product focused future, our team looks to unify the look and feel of all our 
          products, in a way where art means digital sciences.
          </p>
          <p className="mb-4">
          We are calling for artists to put forward a proposal (preferably as art) to show us 
          where you think you might take us.
          </p>
        </JobPosting>
        <JobPosting heading="Graduate Software Engineer">
          <p className="mb-4">
          We are looking for graduates who have a passion for building software on the Internet. 
          </p>
          <p className="mb-4">
          While we require no prior experience, what we look for is aptitude to learn and deliver working 
          components of software.
          </p>
          <p className="mb-4">
          You will work under the guidelines of our senior staff members, following templates from our 
          Anomaly Labs projects.
          </p>
          <p className="mb-4">
          Our process involves extensive documentation which provides the Anomaly way of engineering software.
          </p>
          <p className="mb-4">
          We encourage long form writing to support our asynchronous nature of working.
          </p>
          <p className="mb-4">
          Our tech stack is predominantly Python, PostgreSQL, JavaScript (React) which is deployed 
          primarily on Linode with expectations for Amazon Web Services.
          </p>
          <p className="mb-4">
          Whilst many of our projects are delivered via the web, your work will not be limited to web 
          technologies. Given the requirements of our projects, your work will span mobile and 
          at times Internet of Things.
          </p>
          <p className="mb-4">
          At the moment we use the Shape Up to ship our work.
          </p>
          <h3 className="my-4 text-xl font-bold">What are we looking for?</h3>
          <p className="mb-4">
          If you consider yourself a person with stellar habits, eager to learn, with the ability to 
          show up every day and strive to do more. 
          </p>
          <p className="mb-4">
          We value kind and thoughtful people, who care to make incremental change and not disrupt other 
          people’s lives or businesses.
          </p>
          <p className="mb-4">
          We care about where you want to head as part of our team. If you are one who wishes to leave more 
          than what you found, then Anomaly might be the place for you.
          </p>
        </JobPosting>
      </ul>
      <h2 className="max-w-screen-lg mx-4 text-4xl font-bold lg:mx-auto text-anomaly-red">
        Perks and Benefits
      </h2>
      <EmploymentFacts/>
    </Layout>
 );
};
export default CareersPage
